/********************************/
/*********** TEAM UP ************/
/********************************/

$(document).ready(function() {
    //TODO : Rager parce que cette ligne m'a pris une journée de travail
    // $("body").bootstrapMaterialDesign();

    /* On met un background gris quand on est sur une page avec des cards */
    if ($("main .card").length > 0) {
        $("body").addClass("grey");
    }

    /* gestion ouverture menu */
    $("#menu-icon").click(function() {
        $("header").addClass("menu-is-visible");
    });
    $("#effetFlou").click(function() {
        $("header").removeClass("menu-is-visible");
        $("#addPost-logo").addClass("closed");
    });

    /* gestion ouverture canal du menu */
    $("#menu-canal a").click(function() {
        if (
            $(this)
                .parent()
                .hasClass("is-open")
        ) {
            $(this)
                .parent()
                .removeClass("is-open");
        } else {
            $(this)
                .parent()
                .addClass("is-open");
        }
    });

    /* patch menu button radio */
    $("#menu .bmd-form-group").each(function(index) {
        if ($(this).find("input:checked").length > 0) {
            $(this)
                .find(".bmd-radio")
                .addClass("checked");
        }
    });

    /* patch menu button radio */
    $("#menu .bmd-form-group .bmd-radio").click(function() {
        if (!$(this).hasClass("checked")) {
            $("#menu .bmd-form-group .bmd-radio").removeClass("checked");
            $(this).addClass("checked");
        }
    });

    /* Circle progress bar Mon classement */
    var nbTotalPoints = 1800;
    var nbPointsUser = $(".circle").data("nbpoints");
    var pourcentageCircle = nbPointsUser / nbTotalPoints;
    var progressBarOptions = {
        startAngle: -1.55,
        size: 200,
        value: pourcentageCircle,
        lineCap: "round",
        fill: {
            color: "#4BB4E6"
        }
    };

    //activation de l'animation du cercle
    if ($(".circle").length != 0) {
        $(".circle")
            .circleProgress(progressBarOptions)
            .on("circle-animation-progress", function(event, progress, stepValue) {
                $(this)
                    .find("strong")
                    .text(String(Math.round(stepValue * nbTotalPoints)));
            });
    }

    /* Gestion progress bar mon classement */
    $(".progress-bar").width("0%");
    $(".tooltipCircle").css("left", "0%");
    $(".progress-bar").each(function() {
        var each_bar_width = $(this).attr("aria-valuenow");
        $(this).width(each_bar_width + "%");
        if ($(this).attr("aria-valuenow") >= 98) {
            $(".tooltipCircle").css("left", "calc(100% - 25px)");
        } else if ($(this).attr("aria-valuenow") <= 2) {
            $(".tooltipCircle").css("left", "calc(100% - 0px)");
        } else {
            $(".tooltipCircle").css("left", "calc(100% - 12.5px)");
        }
    });

    /* gestion du container de reaction */
    function resizeReactionContainer() {
        $(".containerReactions span").css("height", $(".containerReactions span").width());

        $(".containerReactions a").css("height", $(".containerReactions a").width());
    }

    $(window).on("resize", resizeReactionContainer);
    resizeReactionContainer();

    /* gestion ouverture menu add post */

    $("#addPost-logo").click(function() {
        $("header").addClass("menu-addPost-is-visible");
    });
    $("#effetFlou").click(function() {
        $("header").removeClass("menu-addPost-is-visible");
    });

    /* gestion fermeture menu add post */
    $("#addPost-logo").click(function() {
        if ($(this).hasClass("closed")) {
            $(this).removeClass("closed");
        } else {
            $(this).addClass("closed");
            $("header").removeClass("menu-addPost-is-visible");
        }
    });

    $("#menu-like li").click(function() {
        $("#menu-like li").removeClass("active");
        $(this).addClass("active");

        /* changement du filtre sur le container */
        var filtreName = $(this)
            .find("span:first")
            .attr("id");
        var tmp = "filter-" + filtreName;

        $("#containerLikes").removeClass("filter-like filter-mdr filter-general filter-etonne filter-inquiet filter-applaudir");
        $("#containerLikes").addClass(tmp);
    });

    /* gestion selectionner avatar */
    var widthElement3 = $(".container_selectionnerAvatar .oneAvatarElement").width();
    $(".container_selectionnerAvatar .oneAvatarElement").css("height", widthElement3);

    if ($(".container_selectionnerAvatar .oneAvatarElement").length % 3 == 2) {
        $(".container_selectionnerAvatar").append('<div class="oneAvatarElement"></div>');
    }

    /* remplissage des backgrounds pour les résultats de sondage */
    $("span.choixText").each(function(index) {
        var widthRes = $(this).data("width");
        $(this)
            .find("div")
            .css("width", widthRes);
    });

    /* gestion modifier mon mot de passe clic sur oeil */
    $("form span.oeil").click(function() {
        if ($(this).hasClass("hide")) {
            $(this).removeClass("hide");
            $(this).addClass("show");
        } else {
            $(this).removeClass("show");
            $(this).addClass("hide");
        }
        var cible = $(this)
            .parent()
            .find("input");
        if (cible.attr("type") == "password") {
            cible.attr("type", "text");
        } else {
            cible.attr("type", "password");
        }
    });

    /* gestion ouverture bloc faq */
    $(".fixedLine").click(function() {
        var oneElement = $(this).parent();
        if (oneElement.hasClass("focus")) {
            oneElement.removeClass("focus");
        } else {
            $(".oneElement").removeClass("focus");
            oneElement.addClass("focus");
        }
    });

    /* resize auto des textarea */
    autosize($("textarea"));

    /* gestion des actifs sur les radios boutons des modals */
    $("#modalCanal .bmd-form-group").each(function(index) {
        if (
            $(this)
                .find("input")
                .is(":checked")
        ) {
            $(this).addClass("is-focused-patch");
        }
    });

    $("#modalBadge .bmd-form-group").each(function(index) {
        if (
            $(this)
                .find("input")
                .is(":checked")
        ) {
            $(this).addClass("is-focused-patch");
        }
    });

    /* surdef du systeme de bootstrap qui avait un bug */
    $("#modalCanal .bmd-form-group").click(function() {
        $("#modalCanal .bmd-form-group").removeClass("is-focused-patch");
        $(this).addClass("is-focused-patch");
    });

    $("#modalBadge .bmd-form-group").click(function() {
        $("#modalBadge .bmd-form-group").removeClass("is-focused-patch");
        $(this).addClass("is-focused-patch");
    });

    /* gestion de la hauteur des photos de la page créer post */
    // var widthElement4 = $('.container-image-add .onePhotoElement').width();
    // debugger;
    // $('.container-image-add .onePhotoElement').css('height',Math.round(widthElement4));

    /* gestion du responsive des galeries d'images */
    /* on ajoute un troisème bloc blanc si il n'y a que 2 blocs */
    /* car j'utilise display flex et justify-content:space-bewteen pour des écarts entre les blocs égaux et un meilleur responsive */
    if ($(".container_photos .onePhotoElement").length % 3 == 2) {
        $(".container_photos").append('<div class="onePhotoElement"></div>');
    }

    if ($(".container_selectionner-photos .onePhotoElement").length % 3 == 2) {
        $(".container_selectionner-photos").append('<div class="onePhotoElement"></div>');
    }

    if ($(".container-image-add .onePhotoElement").length % 3 == 2) {
        $(".container-image-add").append('<div class="onePhotoElement"></div>');
    }
});
